.nav {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 99999999;

  .background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 300px;
    background: #fff;
  }

  button {
    outline: none;
    border: none;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 15px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: transparent;
  }

  ul,
  li {
    margin: 0;
    padding: 0;
  }

  .title {
    background: transparent;
    padding: 25px;
    position: absolute;
    top: -2px;
    width: 230px;
    display: flex;
    justify-content: flex-end;
    font-size: 2em;
    font-weight: bold;
    color: hsl(0, 0%, 18%);

    a {
      text-decoration: none;
      color: inherit;
    }
  }

  ul {
    padding: 25px;
    position: absolute;
    top: 50px;
    width: 250px;
  }

  li {
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 3px solid rgb(184, 184, 184);
    transition: 0.5s;
  }

  .link {
    color: black;
    font-size: 1.1em;
    font-weight: bold;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    padding: 10px 20px;
    width: 200px;
    height: 20px;
    flex: 1;
    transition: 0.5s;
  }
  .link-disabled {
    pointer-events: none !important;
    cursor: default !important;
  }

  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

}