div {
  .home-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1 {
      text-align: center;
      font-size: 10em;
    }

    h3 {
      font-size: 3em;
    }

    h1,
    h3 {
      color: rgb(251, 251, 251);
      text-shadow: 5px 0 #000, -5px 0 #000, 0 5px #000, 0 -5px #000,
        2.5px 2.5px #000, -2.5px -2.5px #000, 2.5px -2.5px #000, -2.5px 2.5px #000;
      font-family: 'Courier New', Courier, monospace;
      text-transform: uppercase;
    }
  }
}