.who_am_i {
  color: rgb(0, 0, 0);
  position: absolute;
  text-shadow: 0px 0px 5px rgb(255, 255, 255);
}

.heading {
  font-family: 'Courier New';
  text-transform: capitalize;
  font-weight: 400;
  font-size: 15vw;
  text-align: end;
  top: 35vh;
  right: 5vw;
}

.subtext_1,
.subtext_2,
.subtext_3,
.subtext_4 {
  text-align: justify;
  font-size: 2em;
  max-width: 45vw;
}

.subtext_1,
.subtext_3 {
  right: 5vw;
}

.subtext_2,
.subtext_4 {
  left: 5vw;
}

.subtext_1,
.subtext_2,
.subtext_3,
.subtext_4 {
  align-self: center;
}


.link {
  text-decoration: none;
  color: transparent;
  background: #666666;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.5);
}


@media (max-width: 1200px) {

  .who_am_i {
    color: rgb(0, 0, 0);
    position: absolute;
    text-shadow: 0px 0px 5px rgb(255, 255, 255);
  }
  
  .heading {
    font-family: 'Courier New';
    text-transform: capitalize;
    font-weight: 400;
    font-size: 15vw;
    text-align: end;
  }
  
  .subtext_1,
  .subtext_2,
  .subtext_3,
  .subtext_4 {
    padding: 16px;
    border-radius: 16px;
    background-color: #bababa9f;
    text-align: left;
    font-size: 1em;
    max-width: 60vw;
  }
  
  .subtext_1,
  .subtext_3 {
    right: 10vw;
  }
  
  .subtext_2,
  .subtext_4 {
    left: 10vw;
  }
  
  .subtext_1,
  .subtext_2,
  .subtext_3,
  .subtext_4 {
    align-self: center;
  }
  
  
  .link {
    text-decoration: none;
    color: transparent;
    background: #666666;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.5);
  }
  
}