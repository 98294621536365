@media (max-width: 992px) {
  div {
    .tech-container {

      display: grid;
      grid-template-rows: 1fr 2fr 1fr;


      p {
        text-align: justify;
        margin: 2em;
        grid-row: 3;
        font-family: 'Courier New', Courier, monospace;
        text-transform: uppercase;
      }
    }

  }

}

@media (min-width: 992px) {
  div {

    .container-right {
      p {
        grid-column: 1;
        padding: 0 0 0 3em;
      }
    }

    .container-left {
      p {
        grid-column: 3;
        padding: 0 3em 0 0;
      }
    }

    .tech-container {

      display: grid;
      grid-template-columns: 2fr 1fr 2fr;



      p {
        align-self: center;
        text-align: justify;
        margin: 2em;
        font-family: 'Courier New', Courier, monospace;
        text-transform: uppercase;
      }
    }

  }

}